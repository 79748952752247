import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPermissions } from '@shared/classes';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { EventsImportComponent } from './events-import/events-import.component';
import { EventsItemComponent } from './events-item/events-item.component';
import { EventsListComponent } from './events-list/events-list.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'list' },
  {
    path: 'list',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadEvent],
        redirectTo: '/access',
      },
    },
    component: EventsListComponent,
  },
  {
    path: 'import/:typeId',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.CreateEvent],
        redirectTo: '/access',
      },
    },
    component: EventsImportComponent,
  },
  {
    path: 'create',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.CreateEvent],
        redirectTo: '/access',
      },
    },
    component: EventsItemComponent,
  },
  {
    path: 'create/clone/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.CreateEvent],
        redirectTo: '/access',
      },
    },
    component: EventsItemComponent,
  },
  {
    path: 'create/:orgId/:parentId',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.CreateEvent],
        redirectTo: '/access',
      },
    },
    component: EventsItemComponent,
  },
  {
    path: 'edit/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.UpdateEvent],
        redirectTo: '/access',
      },
    },
    component: EventsItemComponent,
  },
  {
    path: 'view/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadEvent],
        redirectTo: '/access',
      },
    },
    component: EventsItemComponent,
  },
  {
    path: 'settings',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.DeleteEvent],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'bulk-operation',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.DeleteEvent],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./bulk-operations/bulk-operations.module').then((m) => m.BulkOperationsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventsRoutingModule {}
