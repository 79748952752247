import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  BaseTablePage,
  ButtonColor,
  EntityTypeFieldDto,
  EventFieldDto,
  makePlural,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  PermissionActions,
  routeToLocaleCase,
  toCamelCase,
  toKebabCase,
} from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ExportDataService } from '@shared/services/export-data.service';
import { EventsMappingService } from '@shared/services/mappings/entity/events-mapping.service';
import { AppInjector } from 'app/app-injector';
import { EventsDataService } from '../../services/data/events-data.service';
import { EventsItemFormComponent } from '../events-item-form/events-item-form.component';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent extends BaseTablePage<EventFieldDto> implements OnInit {
  editDialogComponent = EventsItemFormComponent;
  // additionalStaticFilters: FilterItem[] = [{ "property": "category", "operation": "EQUAL", "value": Entity.CategoryEnum.Events }]
  constructor(
    requestService: EventsDataService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router,
    public localeService: LocaleService
  ) {
    super(
      requestService,
      exportService,
      appDialogService,
      router,
      {
        moduleKeyword: ModuleKeywords.Event,
        routePrefix: ModuleRoutePrefix.Entity,
      },
      localeService
    );
    this.SetPageValues({
      breadCrumb: {
        items: [
          {
            label: this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
            ),
            routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
          },
          {
            label: this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
            ),
            routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
          },
        ],
      },
      pageActions: [
        {
          label:
            this.localeService.translate('general.actions.add') +
            ' ' +
            this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
            ),
          id: 1,
          color: ButtonColor.Primary,
          icon: 'pi pi-plus',
          iconPos: 'left',
          command: this.addNewItem.bind(this),
          permission: `${PermissionActions.Create}${this.moduleKeyword}`,
        },
        {
          label: 'Import (Beta)',
          id: 2,
          color: ButtonColor.Primary,
          icon: 'pi pi-plus',
          iconPos: 'left',
          command: this.showDialog.bind(this),
          permission: `${PermissionActions.Create}${this.moduleKeyword}`,
        },
      ],
    });
  }

  ngOnInit(): void {}

  setCols() {
    const mappingService = AppInjector.get(EventsMappingService);
    this.cols = [...mappingService.tableFields].map((x) => {
      return {
        ...x,
        name: this.localeService.translate(
          `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.fields.${x.key}.label`,
          x.name
        ),
        translationKeyPrefix:
          x.translationKeyPrefix ||
          `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.fields.${x.key}`,
      };
    });
  }
  setTableActions() {
    this.tableActions = [
      {
        id: 2,
        icon: 'pi pi-sitemap',
        color: ButtonColor.Secondary,
        command: this.goToParentTreePage.bind(this),
        permission: `${PermissionActions.Read}${ModuleKeywords.Event}`,
        tooltipText: 'Organization Chart',
        label: '',
        group: { id: 2, type: 'split' },
      },
    ];
  }
  visible: boolean = false;
  categoryList = [EntityTypeFieldDto.CategoryEnum.Event];
  entityTypeFormControl = new FormControl(null);
  showDialog() {
    this.visible = true;
  }
  onEntityTypeChange(event) {}
  importItems(): any {
    this.visible = false;
    this.router.navigateByUrl(
      `${this.routePrefix}${toKebabCase(this.moduleKeyword)}/import${this.entityTypeFormControl.value ? '/' + this.entityTypeFormControl.value.code : ''}`
    );
  }
  goToParentTreePage(row): any {
    this.router.navigateByUrl(
      `${this.routePrefix}${toKebabCase(this.moduleKeyword)}/tree/${(row as any)?.organization}/${(row as any).code || (row as any).id}`
    );
  }
}
